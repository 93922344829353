nav {
    &.is-anchored {
        .gNav {
            background-color: $blue;
        }
    }

    &.is-stuck {
        .gNav {
            background-color: rgba($color: $blue, $alpha: .9);
        }
    }

    .gNav {
        z-index: 10;

        @include breakpoint (medium down) {
            position: relative;
            margin-top: 4rem;
        }

        // background-color: rgba($color: $white, $alpha: .9);
        padding-top: .5rem;
        padding-bottom: .5rem;
        padding-right: 3rem;
        width: 100%;
        justify-content: flex-end;

        @include breakpoint (medium down) {
            background-color: transparent;
        }

        li {
            position: relative;
            margin-top: .25rem;
            margin-bottom: .25rem;
            display: flex;
            align-items: center;

            @include breakpoint (medium down) {
                border-bottom: 1px solid $light-gray;
                margin-left: .5rem;
                margin-right: .5rem;
            }

            a {
                padding-top: 0;
                padding-bottom: 0;
                line-height: 1.5;
                color: $white;

                &:hover,
                &:focus {
                    font-weight: bold;
                }

                @include breakpoint (medium down) {
                    text-decoration: none;
                    font-weight: bold;
                    padding-top: .25rem;
                    padding-bottom: .25rem;
                }

                &.button {
                    border-color: $white;
                    color: $white;

                    &:hover,
                    &:focus {
                        border-color: $white;
                        color: $dark-gray;
                        background-color: $coral;
                        // transform: none;
                        // font-weight: normal;
                    }
                }
            }
        }
    }
}

.off-canvas {
    background-color: rgba(256, 256, 256, 0.75);

    .close-button {
        background-color: #696969;
        color: #fefefe;
        padding-left: .25rem;
        padding-right: .25rem;
        height: 2rem;
        width: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.6rem;
        line-height: 0;
    }
}

// SP Nav {
#offCanvas {
    .spNav {
        margin-top: 3rem;

        .gNav {
            li {
                border-bottom: 1px dotted $gray;

                &:last-of-type {
                    margin-top: 2rem;
                    padding-left: 1rem;
                    padding-right: 1rem;
                    border-bottom: none;
                }

                a {
                    font-weight: bold;
                    line-height: 1.4;
                }
            }
        }
    }
}

[data-sticky] {
    width: 100%;
}