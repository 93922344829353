#post {
    h2.h3 {
        background-color: transparent;
        color: $black;
        border-left: 10px solid $deep-blue;
        border-bottom: 2px solid $deep-blue;
        padding: 0 0 0 .5rem;
    }

    h3.h4 {
        border-bottom: none;
        border-left: 15px solid $deep-blue;
        padding-left: .5rem;
    }

    h4.h5 {
        border-left: none;
        margin-left: 1.5rem;
        margin-top: 1.5rem;
        display: block;
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;

        &:before {
            position: absolute;
            content: '';
            width: 1em;
            height: 1em;
            background-color: $deep-blue;
            left: -1em;
        }
    }

    p,
    ul,
    ol,
    dl {
        padding-left: 2rem;
    }
}