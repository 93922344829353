footer {
    background-color: $deep-blue;
    margin-top: 5rem;
    padding: 2.5rem 0;
    p {
        color: $dark-gray;
    }

    .callout {
        border-radius: .5rem;
        ul.menu {
            li {
                a {
                    color: $blue;
                    text-decoration: underline;
                    display: inline-block;
                    &:hover {
                        font-weight: bold;
                    }
                }

            }
        }
        .char-img {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            &.show-for-large img {
                max-width: 4rem;
            }
            &.hide-for-large img {
                max-width: 12.5rem;
            }
        }        
    }
    .address {
        @include breakpoint (medium down){
            margin-top: 2rem;
        }
        .address-desc {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            img {
                max-width: 5rem;
                @include breakpoint (small only){
                    max-width: 8rem;
                }
            }
        }
    }
    hr {
        border-bottom: 1px solid $white;
    }

    small {
        display: block;
        text-align: center;
        color: $white;
    }

}