.kome {
    list-style-type: none;
    margin-left: 2rem;

    li {
        padding-left: 1rem;
        position: relative;

        &::before {
            position: absolute;
            content: "※";
            top: 0;
            left: 0;
            line-height: 1;
            font-size: 120%;
            color: $red;
        }
    }
}

.dots-list {
    list-style: square;
}

.icon-list {
    margin-left: 2.5rem;
    list-style-type: none;

    li {
        margin-bottom: 1rem;
        text-indent: -0.8rem;
        margin-left: 1rem;

        i {
            &:first-child {
                margin-right: .5rem;
            }

            &:not(:first-child) {
                text-indent: 0;
            }
        }
    }
}

.dl-horizontal {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 2rem;

    dt {
        width: 15%;
    }

    dd {
        width: 85%;
    }

    @include breakpoint (small only) {

        dt,
        dd {
            width: 100%;
        }

        dd {
            padding-left: 2rem;
        }
    }
}

dl {
    padding-left: 2rem;

    dd {
        padding-left: 2rem;

        dl {
            padding-left: 0;
        }
    }
}

//リンクリスト
.link-list {
    list-style: none;

    li {
        &::before {
            content: "\f138";
            font-family: "Font Awesome 5 Free";
            font-weight: bold;
            color: $deep-blue;
            margin-right: .25rem;
        }
    }

    &.following-link-list {
        li {
            margin-bottom: 1rem;

            a {
                text-decoration: none;
            }
        }
    }
}

// 丸数字
ol.circle-num {
    counter-reset: my-counter;
    list-style: none;
    padding: 0;

    >li {
        margin-bottom: 1rem;
        padding-left: 2rem;
        position: relative;

        &::before {
            content: counter(my-counter);
            counter-increment: my-counter;
            color: $white;
            background-color: $deep-blue;
            display: block;
            float: left;
            line-height: 2rem;
            margin-left: -2.5rem;
            text-align: center;
            height: 2rem;
            width: 2rem;
            border-radius: 50%;
        }
    }
}
ol.circle-num-thin {
    counter-reset: my-counter;
    list-style: none;
    padding: 0;

    >li {
        margin-bottom: 1rem;
        padding-left: 2rem;
        position: relative;

        &::before {
            content: counter(my-counter);
            counter-increment: my-counter;
            color: $black;
            border: 1px solid $black;
            background-color: $white;
            display: block;
            float: left;
            line-height: 1.5rem;
            margin-left: -2rem;
            text-align: center;
            height: 1.5rem;
            width: 1.5rem;
            border-radius: 50%;
        }
    }
}

// adviser snsリスト
#adviser {

    .menu {
        flex-wrap: nowrap;
    }
}

.no-list {
    list-style: none;
}
// menu li項目館にスラッシュ"/"挿入
.menu.slash-padding li {
    padding-right: .5rem;
    padding-left: .5rem;
    position: relative;
    &::after {
        position: absolute;
        content: "/";
        padding-left: .25rem;
    }
    &:last-of-type {
        padding-right: 0;
        &::after {
            content: none;
            padding-left: 0;
        }
    }
}