$blue : #004BB1;
$sky-blue : #CDDFF8;
$light-sky-blue : #e9f1fc;
$light-blue : #F2FEFF;
$light-blue2 : #e6fdff;
$marine-blue : #3E8DC0;
$marine-blue-dark : #32709a;
$deep-blue : #3676C1;
$violet : #1D2182;
$brown-orange : #E58860;
$coral : #ff7f50;
$coral-pink : #f88379;
$brown-sand-orange : #FFA300;
$gray : #a9a9a9;
$dark-gray : #383838;
$black : #080808;
$light-gray : #c0c0c0;
$red : #e00000;
$white : #fefefe;
$yellow : #FAFA33;
$light-yellow : #fffbc8;
.red {
    color: $red;
}
.blue {
    color: $blue;
}
.white {
    color: #fff;;
}
// background-color
.bg-gray {
    background-color: $gray;
}
.bg-lightgray {
    background-color: $light-gray;
}
.bg-white {
    background-color: #fff;
}

 