.media-object {
    .media-object-section {
        img {
            max-width: 100%;
        }
    }
}
// Top
#topPage {
// banner
    .activity {
        .block {
            img {
                object-fit: cover;
                aspect-ratio: 16 / 9;
            }
        }
    }
}
// contact
#contact .char-img {
    img {
        max-width: 20rem;
    }
}