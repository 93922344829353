.header-title {
    max-height: 20rem;
    background-repeat: no-repeat;
    background-size: cover;
    &#info {
        background-image: url(#{$path}assets/img/common/xxx.png);
    }
    h1 {
        border: none;
        text-align: center;
        span {
            background-color: rgba($color: $white, $alpha: .8);
            padding: .5rem 1rem;
            border-radius: 10px;
        }
    }
}