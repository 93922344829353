
    section .callout {
            background-color: rgba($white, 0.9);
            padding: 1.5rem;
            max-width: 100%;
            &.roundedge {
                border-radius: 1rem;
            }
        }
    
