.breadcrumbs-wrapper {
    padding-top: 2rem;
    padding-left: 3rem;
    @include breakpoint (small only){
        padding-left: 0.5rem;
    }
    .breadcrumbs {
        li {
            font-size: .9rem;
            background-color: rgba($color: #fff, $alpha: .85);
            padding: .5rem;
            a {
                color: $blue;
            }
        }
    }
}