#topPage {

    // heading setting {
    // heading clear {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        border: none;
        background-color: transparent;
        color: $dark-gray;
    }

    // heading
    .latest {
        .whatsNew {
            h2 {
                text-align: center;
                font-weight: bold;
                color: $marine-blue-dark;

                span {
                    position: relative;
                    display: inline-block;
                    background-color: $white;
                    border-radius: 1rem;
                    border: 1px solid $marine-blue;
                    padding-left: 1rem;
                    padding-right: 1rem;

                    &::before,
                    &::after {
                        position: absolute;
                        content: "";
                        display: inline-block;
                        background-size: contain;
                        background-repeat: no-repeat;
                        width: 3rem;
                        height: 3rem;
                        background-position: center;
                    }

                    &::before {
                        background-image: url(#{$path}assets/img/common/header-flower-l.png);
                        left: -3.5rem;
                    }

                    &::after {
                        background-image: url(#{$path}assets/img/common/header-flower-r.png);
                        right: -3.5rem;
                    }
                }
            }
        }

        .recentEvent {
            h2 {
                font-size: 1.6rem;
            }
        }
    }

    .about,
    .activity,
    .chuou,
    .associate-list {
        h2 {
            text-align: center;
            font-weight: bold;
            color: $marine-blue-dark;

            span {
                position: relative;
                display: inline-block;
                background-color: $white;
                padding: .5rem 1rem;
                border-radius: 1rem;
                border: 1px solid $marine-blue;

                &::before,
                &::after {
                    position: absolute;
                    content: "";
                    display: inline-block;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                    width: 4rem;
                    height: 4rem;

                    @include breakpoint (small only) {
                        width: 3rem;
                        height: 3rem;
                    }
                }

                &::before {
                    left: -4.5rem;

                    @include breakpoint (small only) {
                        left: -2rem;
                    }
                }

                &::after {
                    right: -4.5rem;

                    @include breakpoint (small only) {
                        right: -2rem;
                    }
                }
            }
        }
    }

    .about h2 {
        span {
            &::before {
                background-image: url(#{$path}assets/img/common/header-seaser-l.png);
            }

            &::after {
                background-image: url(#{$path}assets/img/common/header-seaser-r.png);
            }
        }
    }

    .activity h2 {
        span {
            &::before {
                background-image: url(#{$path}assets/img/common/header-vegi-l.png);
            }

            &::after {
                background-image: url(#{$path}assets/img/common/header-vegi-r.png);
            }
        }
    }

    .chuou h2 {
        span {
            &::before {
                background-image: url(#{$path}assets/img/common/header-flower2-l.png);
            }

            &::after {
                background-image: url(#{$path}assets/img/common/header-flower2-r.png);
            }
        }
    }

    .associate-list h2 {
        span {
            &::before {
                background-image: url(#{$path}assets/img/common/header-fish-l.png);
            }

            &::after {
                background-image: url(#{$path}assets/img/common/header-fish-r.png);
            }
        }
    }

    // basic setting
    .cell {
        background-color: $white;
    }

    // スライド
    .slide-top {
        position: relative;
        height: 70vh;

        @include breakpoint (medium only) {
            height: 50vh;
        }

        @include breakpoint (small only) {
            height: 40vh;
        }

        .box-overlay {
            position: absolute;
            z-index: 2;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;

            // height: fit-content;
            .text-frame-wrapper {
                    min-width: 0;
                    min-height: 0;
                    display: inline-block;
                    position: relative;
                    width: 100%;
                .text-frame {
                    position: absolute;
                    background-color: rgba($color: $sky-blue, $alpha: .8);
                    margin-top: 1.5rem;
                    margin-left: 1.5rem;
                    padding-right: 3rem;
                    padding-left: 3rem;
                    border-radius: 1rem;
                    border: .5rem solid $brown-sand-orange;
                    width: fit-content;

                    // @include breakpoint (large) {
                    //     max-width: 70%;
                    // }

                    @include breakpoint (medium only) {
                        padding-left: 4.5rem;
                        padding-right: 3rem;
                        margin-top: 2rem;
                        margin-left: 2rem;
                        margin-right: 2rem;
                    }

                    @include breakpoint (small only) {
                        padding-top: .5rem;
                        padding-left: 1rem;
                        padding-right: 1rem;
                        margin-top: 1rem;
                        margin-left: 1.5rem;
                        margin-right: 1.5rem;
                        border-width: 4px;
                    }

                    &::before,
                    &::after {
                        content: "";
                        position: absolute;
                        content: "";
                        display: inline-block;
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position: top;
                        top: 0;
                        width: 5rem;
                        height: 5rem;

                        @include breakpoint (small only) {
                            width: 3rem;
                            height: 3rem;
                        }
                    }

                    &::before {
                        left: -1.5rem;
                        top: -2rem;
                        background-image: url(#{$path}assets/img/common/jumbo-l.png);
                    }

                    &::after {
                        right: -1.5rem;
                        top: 75%;
                        background-image: url(#{$path}assets/img/common/jumbo-r.png);
                    }

                    p.jumbo {
                        // padding-top: 3rem;
                        // padding-left: 3rem;
                        font-weight: bold;
                        line-height: 1.2;
                        font-size: 2.4rem;
                        margin-right: 0;
                        @include breakpoint (medium down) {
                            font-size: 2rem;
                            // padding: 2rem 1rem 0 1rem;
                        }

                        @include breakpoint (small only) {
                            font-size: 1rem;
                        }

                        // span {
                        //     background-color: rgba($color: $white, $alpha: .8);
                        // }
                    }
                }
            }

            .char-img {
                width: 100%;

                img {
                    object-fit: scale-down;
                    max-width: 55%;

                    @include breakpoint (medium down) {
                        max-width: 70%;
                    }
                }
            }
        }

        .slick-wrapper {
            height: 100%;

            .slick-carousel {
                position: relative;

                .slick-arrow {
                    position: absolute;
                    z-index: 10;
                    top: 50%;

                    &.slick-prev {
                        left: 0;
                    }

                    &.slick-next {
                        right: 0;
                    }
                }

                .slick-panel {
                    img {
                        width: 100%;
                        object-fit: cover;
                        height: 70vh;

                        @include breakpoint (medium only) {
                            height: 50vh;
                        }

                        @include breakpoint (small only) {
                            height: 40vh;
                        }
                    }
                }
            }
        }

        .slick-btn {
            z-index: 15;
            position: absolute;
            bottom: 0;
            background-color: rgba($color: $white, $alpha: .7);
            padding: .5rem;

            .btn-wrapper {
                button {
                    margin: 0;

                    @include breakpoint (small only) {
                        padding: .5rem;
                    }
                }
            }
        }
    }

    // 新着
    .latest {
        margin-top: 5rem;
        padding-left: 5rem;
        padding-right: 5rem;

        @include breakpoint (small only) {
            padding-left: 1rem;
            padding-right: 1rem;
            margin-top: 2rem
        }

        // 新着一覧
        .whatsNew {
            @include shadow($prototype-box-shadow);
        }

        // 新着イベント
        .recentEvent {
            // border: 3px solid $marine-blue;
            // border-radius: 1rem;
            @include shadow($prototype-box-shadow);

            @include breakpoint (medium down) {
                margin-top: 2rem;
            }

            .media-object {
                margin-top: 2rem;

                .date {
                    font-weight: bold;
                }
            }

        }
    }

    // 沖縄県金融広報委員会について
    .about {
        margin-top: 5rem;

        @include breakpoint (small only) {
            margin-top: 2rem;
        }

        .cell {
            padding: 1rem;
            @include shadow($prototype-box-shadow);

            .media-object-section {
                @include breakpoint (medium up) {
                    margin-left: 1rem;
                    margin-right: 1rem;
                }

                p {
                    &:first-of-type {
                        margin-top: 1.5rem;
                    }
                }
            }
        }
    }

    // 活動内容
    .activity {
        margin-top: 5rem;
        padding: 0 3rem;

        @include breakpoint (small only) {
            padding-left: 1rem;
            padding-right: 1rem;
            margin-top: 2rem;
        }

        .cell {
            padding: 1rem;
            @include shadow($prototype-box-shadow);

            @include breakpoint (small only) {
                margin-top: 2rem;
            }

            h3 {
                text-align: center;
            }

            img {
                object-fit: cover;
                aspect-ratio: 16 / 9;
                // height: 12.5rem;
            }

        }
    }

    // 金融広報中央委員会
    .chuou {
        margin-top: 5rem;
        padding: 0 3rem;

        @include breakpoint (small only) {
            padding-left: 1rem;
            padding-right: 1rem;
            margin-top: 2rem;
        }

        .cell:nth-child(2) {
            padding: 0;
            background-color: transparent;

            @include breakpoint (medium down) {
                margin-top: 3rem;
            }
        }

        .manebita {
            @include shadow($prototype-box-shadow);
            padding: 1rem;
        }

        .literacy {
            @include shadow($prototype-box-shadow);
            padding: 1rem;
            background-color: $white;

            h3 {
                text-align: center;
                margin-top: .5rem;
                margin-bottom: 3.5rem;
                border: none;
                padding-left: 0;
                span {
                    border: 3px solid $coral;
                    padding: .5rem 1rem;
                    border-radius: .5rem;
                    color: $dark-gray;
                    font-weight: bold;
                    position: relative;

                    &::before,
                    &::after {
                        position: absolute;
                        content: "";
                        display: inline-block;
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position: top;
                        top: 0;
                        width: 5rem;
                        height: 5rem;
                    }

                    &::before {
                        left: -5.5rem;
                        background-image: url(#{$path}assets/img/common/header-literacy-l.png);
                    }

                    &::after {
                        right: -5.5rem;
                        background-image: url(#{$path}assets/img/common/header-literacy-r.png);
                    }
                }
            }
        }

        .media-object {
            .media-object-section {
                .banner-list {
                    list-style: none;
                    display: flex;
                    flex-direction: column;
                    margin-left: 0;

                    li {
                        @include shadow($prototype-box-shadow);
                        background-color: $white;
                        margin-bottom: 1rem;
                        display: flex;
                        align-items: center;

                        a {
                            padding: 1rem .5rem;
                            width: 100%;
                            display: block;
                            text-decoration: none;

                            &::before {
                                content: "\f138";
                                font-family: "Font Awesome 5 Free";
                                font-weight: bold;
                                margin-right: .5rem;
                            }

                            &:hover {
                                background-color: $light-sky-blue;
                                color: $dark-gray;
                            }
                        }
                    }
                }

                &.banner-chuou {
                    padding: 0;
                    @include shadow($prototype-box-shadow);
                    border-radius: 1.2rem;

                    a {
                        border-radius: 1.2rem;
                        background-color: $white;
                        display: block;
                        padding: 1rem;
                        height: 100%;

                        &:hover {
                            background-color: $light-sky-blue;
                        }
                    }
                }
            }
        }
    }

    // 関連リンク
    .associate-list {
        margin-top: 5rem;
        padding: 0 3rem;
        background-color: $white;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;

        @include breakpoint (small only) {
            padding-left: 1rem;
            padding-right: 1rem;
            margin-top: 2rem;
        }

        .cell {
            position: relative;
            background-color: transparent;
            // padding: 1rem;
            // @include shadow($prototype-box-shadow);
            display: flex;
            justify-content: center;
            align-items: center;

            @include breakpoint (medium down) {
                margin-top: 2rem;
            }

            a {
                text-decoration: none;
            }
        }
    }

    .bottom-image {
        margin-top: 3rem;
    }
}