// base setting
.card {
    padding: 0 !important;

    img {
        object-fit: cover;
        width: 100%;
    }
    &.card-blank {
        background-color: transparent!important;
        border: none!important;
        box-shadow: none!important;
    }
}

// bok-vide0
#book-video {
    .card {
        background-color: $light-yellow;
    }
}

// adviser
#adviser {
    .adviser-card {
        .card {
            background-color: $light-blue;
            @include shadow($prototype-box-shadow);

            .media-object {
                .media-object-section:nth-of-type(1) {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-evenly;
                    align-items: center;
                    img.char {
                        max-width: 80%;
                    }
                }

                .media-object-section:nth-of-type(2) {
                    padding: .5rem;
                    dl {
                        dt {
                            position: relative;
                            display: flex;
                            align-items: center;

                            &::before {
                                position: absolute;
                                content: '';
                                width: 1rem;
                                height: 1rem;
                                background-color: $deep-blue;
                                margin-left: -1.25em;
                            }
                        }

                        dd {
                            padding-left: 0;
                        }
                    }
                }

            }
        }
    }
}

// eventinfo-221210.html
.ev221210 {
    .card {
        border-radius: .5rem;
        .card-header {
            background-color: $violet;
            color: $white;
            font-weight: bold;
        }
        .card-section {
            padding-left: .5rem;
            padding-right: .5rem;
            &:nth-of-type(3) {
                padding-top: 0;
                padding-bottom: 0;
            }
            dl {
                padding-left: 0!important;
                display: flex;
                flex-wrap: wrap;
                dt {
                    margin-bottom: 0;
                    width: 20%;
                }
                dd {
                    padding-left: 1rem;
                    width: 80%;
                }
            }
        }
        .card-section-img {
                padding: 0;
            img {
                object-fit: cover;
                aspect-ratio: 4 / 3;
            }
        }
        .card-footer {
            padding: .25rem .5rem;
            background-color: $marine-blue;
        }
            ul {
                margin-left: 0!important;
                padding-left: .5rem!important;
                font-size: 90%;
            }
    }
}