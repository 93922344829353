// Search form 
header {
      .searchform {
        display: flex;
        justify-content: flex-end;
        button {
            border-radius: 0;
            box-shadow: none;
            transform: none;
        }
    }
}
// Google Site Search
.cse .gsc-control-cse, .gsc-control-cse {
  background-color: transparent!important;
  border: none!important;
  padding: 0!important;
}
form.gsc-search-box {
  margin: 0!important;
  width: 240px!important;
  table {
    margin-bottom: 0!important;
    overflow:hidden !important;
      tbody {
                background-color: transparent;
                border: none;
        td.gsc-input {
          padding-right:0!important;
          .gsc-input-box {
            // border-radius: 10px 0 0 10px;
            padding-top: 1px;
            padding-bottom: 0.5px;
            .gsib_a {
              padding:0!important;
              input {
                width: 100%!important;
                // border-radius: 10px 0 0 10px;
                padding: 10px 0 10px 10px!important;
                height: 1.9rem!important;
              }
            }
            .gsib_b {
              padding: 0!important;
              .gsst_b {
                padding: 0!important;
                a {
                  padding:inherit ;
                }
              }
            }
          }
      }
      td.gsc-search-button {
        // border-radius: 0 10px 10px 0!important;
        padding-left: 0!important;
        button {
          padding: 9.5px 12px!important;
          margin: 0!important;
          border: none!important;
          // border-radius: 0 10px 10px 0;
          cursor: pointer;
          &:hover {
            background-color: lighten($color: $dark-gray, $amount: 15%);
          }
        }
      }
    }
  }
}
$animated-search-form-initial-width: 10rem;
$animated-search-form-transition-duration: 0.4s;

.animated-search-form[type=text] {
  width: $animated-search-form-initial-width;
  border: 0.125rem solid $light-gray;
  box-shadow: 0 0 3.125rem rgba(0,0,0, 0.18);
  border-radius: $global-radius;
  background-image: url('https://image.ibb.co/i7NbrQ/search_icon_15.png');
  background-position: 0.625rem 0.625rem;
  background-repeat: no-repeat;
  padding: 0.75rem 1.25rem 0.75rem 2rem;
  transition: width $animated-search-form-transition-duration ease-in-out;

  &:focus {
    width: 100%;
  }
}

