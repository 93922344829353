// menu button
#page-top {
    .top-bar-wrapper {
        @include breakpoint (medium down) {
            padding-bottom: 0;
        }

        .title-wrapper {
            display: flex;
            justify-content: center;

            h1 {
                font-size: 5rem;
            }
        }

        .top-bar {
            background-color: transparent;
            align-items: flex-start;
            background-image: url(#{$path}assets/img/common/okinawa_header.png),
            url(#{$path}assets/img/common/header-top.jpg);
            background-size: contain, cover;
            background-repeat: no-repeat;
            background-position: center;
            position: relative;

            @include breakpoint (small only) {
                justify-content: center;
            }

            .top-bar-title {
                display: flex;
                align-items: center;

                p {
                    padding-left: 1rem;
                    margin-bottom: 0;

                    img {
                        width: 12rem !important;
                    }
                }
            }

            .top-bar-right {
                @include breakpoint (large) {
                    margin-right: 1.5rem;
                }

                .menu-icon {
                    position: relative;

                    span {
                        position: absolute;
                        top: 0;
                        left: -60px;

                        @include breakpoint(small only) {
                            display: none;
                        }

                        &:hover {
                            color: lighten($color: #000000, $amount: 40%)
                        }
                    }
                }
            }

            &.pcTitle {
                min-height: 10rem;
            }

            // SP表示
            &.spTitle {
            background-image: url(#{$path}assets/img/common/header-top.jpg);
            background-size: cover;
                .top-bar-left,
                .top-bar-right {
                    flex-basis: auto;
                }

                .top-bar-left {
                    display: flex;

                    p {
                        margin-bottom: 0;
                        display: inline-block;

                        img {
                            width: 16rem;
                        }
                    }

                    h1 {
                        display: inline-block;
                        font-size: 2rem;
                        margin-left: 2rem;
                        font-family: ta-mincho-gf-01, sans-serif;
                        font-weight: 400;
                        font-style: normal;
                    }
                }

                .top-bar-right {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-end;

                    @include breakpoint (small only) {
                        width: 100%;
                    }
                    button.hamburger {
                        background-color: $coral-pink;
                        color: $dark-gray;
                    }
                }
            }
        }



    }
}

.header {
    h1 {
        color: #fff;
        line-height: 1.2;
        text-align: center;
        margin: 1.5rem 0;

        @include breakpoint (small only) {
            margin: 0.5rem 0;
        }
    }
}