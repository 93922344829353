body {
    background-color: $light-blue2;
    background-color: $light-sky-blue;
    background-image:
        radial-gradient(circle at 100% 150%, $light-sky-blue 24%, white 24%, white 28%, $light-sky-blue 28%, $light-sky-blue 36%, white 36%, white 40%, transparent 40%, transparent),
        radial-gradient(circle at 0 150%, $light-sky-blue 24%, white 24%, white 28%, $light-sky-blue 28%, $light-sky-blue 36%, white 36%, white 40%, transparent 40%, transparent),
        radial-gradient(circle at 50% 100%, white 10%, $light-sky-blue 10%, $light-sky-blue 23%, white 23%, white 30%, $light-sky-blue 30%, $light-sky-blue 43%, white 43%, white 50%, $light-sky-blue 50%, $light-sky-blue 63%, white 63%, white 71%, transparent 71%, transparent),
        radial-gradient(circle at 100% 50%, white 5%, $light-sky-blue 5%, $light-sky-blue 15%, white 15%, white 20%, $light-sky-blue 20%, $light-sky-blue 29%, white 29%, white 34%, $light-sky-blue 34%, $light-sky-blue 44%, white 44%, white 49%, transparent 49%, transparent),
        radial-gradient(circle at 0 50%, white 5%, $light-sky-blue 5%, $light-sky-blue 15%, white 15%, white 20%, $light-sky-blue 20%, $light-sky-blue 29%, white 29%, white 34%, $light-sky-blue 34%, $light-sky-blue 44%, white 44%, white 49%, transparent 49%, transparent);
    background-size: 6rem 3rem;

    .off-canvas-content {

        #singlePage,
        #post {
            .grid-container {
                >section {
                    background-color: $white;
                    margin-bottom: 5rem;
                    @include shadow($prototype-box-shadow);

                    .grid-x .cell.main-area {
                        padding: 1rem 2rem 5rem 2rem;

                        p,
                        ul,
                        ol,
                        dl {
                            padding-left: 2rem;
                        }
                    }
                }
            }
        }

        footer {
            margin-top: auto;
        }
    }
}