
.textbox {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}
a {
    color: $blue;
    text-decoration: underline;
    &:hover {
        color: lighten($color: $blue, $amount: 15%);
    }
}
