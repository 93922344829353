// Font size and design
h1,
h2,
h3,
h4,
h5,
h6 {
    @include breakpoint (large) {
        margin-bottom: 2rem !important;
    }
}

#singlePage {

    h3,
    h4,
    h5,
    h6 {
        @include breakpoint (large) {
            margin-top: 1.5rem !important;
        }
    }

}


#main-content {
    h1 {
        display: block;
        text-align: center;
        padding: 1rem 2rem;
        position: relative;
        background-color: $deep-blue;
        color: $white;
        font-weight: bold;

        @include breakpoint (small only) {
            padding: 0;
        }

        small {
            color: $white;
            font-style: italic;
            font-size: 60%;
        }
    }

    h2, .h2
     {
        background-color: $deep-blue;
        color: $white;
        border-left: 1rem double $white;
        padding: 1rem;

        &:not(:first-of-type) {
            margin-top: 4rem;
        }

        small {
            color: $white;
            // font-style: italic;
        }
        span.sub {
            font-size: 1.4rem;
        }
    }



    h3, .h3 {
        border-bottom: 2px solid $deep-blue;
        border-left: 10px solid $deep-blue;
        padding-left: .5rem;

        &.text-center {
            border-left: none;
            border-bottom: none;
            padding: 0;

            span {
                border-top: 5px solid $deep-blue;
                border-bottom: 5px solid $deep-blue;
                padding-left: .5em;
                padding-right: .5em;
            }
        }
    }

    h4, .h4 {
        border-left: 15px solid $deep-blue;
        padding: 0 0 0 .5rem;
        font-weight: normal;
        background-color: transparent;
        color: $black;
        text-align: left;
    }

    h5, .h5 {
        margin-left: 1.5rem;
        margin-top: 1.5rem;
        padding-left: .5rem;
        display: block;
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: transparent;
        color: $black;
        font-weight: normal;
        &:before {
            position: absolute;
            content: '';
            width: 1em;
            height: 1em;
            background-color: $deep-blue;
            left: -1em;
        }
    }

    h6, .h6 {
        display: block;
        border-bottom: 2px solid lighten($color: $deep-blue, $amount: 5);
        margin-left: 1.5rem;
    }

    p {
        margin-left: 1rem;
        margin-right: 1rem;
    }

}

// Font size utility
.fs150 {
    font-size: 150%;
}

.fs140 {
    font-size: 140%;
}

.fs130 {
    font-size: 130%;
}

.fs120 {
    font-size: 120%;
}

.fs110 {
    font-size: 110%;
}

.fs90 {
    font-size: 90%;
}

.fs80 {
    font-size: 80%;
}

.fs70 {
    font-size: 70%;
}

.fs60 {
    font-size: 60%;
}

.fs50 {
    font-size: 50%;
}
dl {
    .lightweight {
        font-weight: lighter;
    }
    .weight-normal {
        font-weight: normal;
    }
    .bold {
        font-weight: bold;
    }
}
// URLリンクの文字列折り返し
#main-content section a.word-break {
    word-break: break-all;
}
strong.thin {
    font-weight: normal;
}
