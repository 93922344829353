.blockskip {
    margin: 0;

    &:focus {
        margin: inherit;
    }
}

noscript {
    img {
        width: inherit;
    }
}

a.disable {
    pointer-events: none;
}
@media(min-width: 1024px){
  a[href^="tel:"]{
    pointer-events: none;
  }
}
.tel-link {
    text-decoration: none;
}