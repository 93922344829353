.slideUp.delighter {
    transition: all .5s ease-out;
    transform: translateY(50%);
    opacity: 0;
    @include breakpoint (small only){
        transform: translateY(25%);
        transition: all .3s ease-out;
    }
}
.slideUp.delighter.started {
    transform: none;
    opacity: 1;
}
/* 基本のスタイル */
.titleAnime.delighter {
    transition: all .7s ease-out;
    transform: translateY(-100%);
    opacity: 0;
}

/* スタート時のスタイル */
.titleAnime.delighter.started {
    transform: none;
    opacity: 1;
}