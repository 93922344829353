table {
  td {
    .badge-new {
      background-color: $red;
      color: $white;
      padding: 2px 4px;
    }
    .badge-secondary {
      background-color: $dark-gray;
      color: $white;
      padding: 2px 4px;
    }
  }
}

#topPage {
  .whatsNew {
    table {
      width: 100%;
      display: block;

      tbody {
        display: block;
        border: 1px solid $marine-blue;
        max-height: 20rem;
        overflow-y: scroll;

        tr {
          background-color: transparent;
          border-bottom: 1px solid $marine-blue;

          th {
            width: 25%;
            color: $dark-gray;
            background-color: $sky-blue;
            border-right: 1px dotted $gray;
          }

          td {
            width: 80%;
          }
        }
      }
    }
  }
}

#singlePage {
  table.row-style {
    tr {
      border: 1px solid $gray;
      border-bottom: 1px solid $light-gray;

      &:last-of-type {
        border-bottom: 1px solid $gray;
      }

      &:nth-of-type(even) {
        background-color: $light-sky-blue;
      }

      th {
        background-color: $deep-blue;
        color: $white;
        width: 20%;
        text-align: left;
      }

      td {
        width: 80%;
      }
    }
  }

  table.col-style {
    tr {
      border: 1px solid $gray;
      border-bottom: 1px solid $light-gray;

      &:last-of-type {
        border-bottom: 1px solid $gray;
      }

      &:nth-of-type(even) {
        background-color: $light-sky-blue;
      }

      th {
        background-color: $deep-blue;
        color: $white;
        border-right: 1px solid $light-gray;

        &:last-of-type {
          border-right: none;
        }
      }
    }
  }

  #a11y {
    table.col-style {
      thead {
        tr {
          th {
            text-align: center;
          }
        }
      }

      tbody {
        tr {
          td {
            border-right: 1px solid $gray;

            &:last-of-type {
              border-right: none;
            }
          }
        }
      }
    }
  }
}

.grid-list {
  margin-top: 3rem;

  td {
    padding: 0;
    vertical-align: top;
    width: calc(100% / 5);

    > dl {
      padding: 0 !important;

      > dt {
        background-color: $violet;
        color: $white;
        padding: 0.5rem;
        margin: 0;
        // border-radius: .75rem;
        // box-sizing: border-box;
        width: 100%;
      }

      > dd {
        padding-left: 0;

        > dl {
          padding-left: 0 !important;

          @include breakpoint(medium) {
            width: 60%;
          }
        }
      }
    }

    .kome {
      margin-left: 0;
      padding-left: 0 !important;
    }
  }
}
table.border01 {
  thead {
    border: 1px solid $black;

    th {
      background-color: $light-blue2;
      border: 1px solid $black;
    }
  }
  tbody {
    border: 1px solid $black;
    tr {
      &:nth-of-type(even) {
        background-color: $white;
      }
      td {
        border: 1px solid $black;
      }
    }
  }
}
