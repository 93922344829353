
.w100 {
    width: 100%;
}
.w1-3rd {
    width: calc(100% / 3);
}
.w1-6th {
    width: calc(100% / 6);
}
.w1-7th {
    width: calc(100% / 7);
}
.w1-8th {
    width: calc(100% / 8);
}
.w1-9th {
    width: calc(100% / 9);
}
// Media query 
.w100-mq {
    @include breakpoint (small only){
        width: 100%;
    }
}

$value:100;

@while $value>0 {
  .ws__#{$value} {
    width: $value + 0%!important;
    @include breakpoint(small only){
        width: 100%;
    }
  }

  $value:$value - 5;
}
$value:100;

@while $value>0 {
  .w__#{$value} {
    width: $value + 0%!important;
  }

  $value:$value - 5;
}

