        ul.table-list {
            list-style: none;
            margin: 0;
            li {
                width: 100%;
                display: inline-block;
                background-color: $white;
                border-bottom: 1px dotted $light-gray;
                &:last-child{
                    border-bottom: none;
                    border-radius: 0 0 5px 5px;
                }
                a {
                    color: $blue;
                    &:hover {
                        color: lighten($color: $blue, $amount: 20%);
                    }
                }
                    span {
                        display: inline-block;
                        vertical-align: middle;
                    &:nth-of-type(1){
                        width: 20%;
                        padding-top: .25rem;
                        padding-left: 1rem;
                        border-right: 2px dotted $light-gray;
                        @include breakpoint (small only){
                            width: 30%;
                            padding-left: 0;
                        }
                    }
                    &:nth-of-type(2){
                        width: 80%;
                        padding-top: .25rem;
                        padding-bottom: .25rem;
                        padding-left: 1rem;
                        @include breakpoint (small only){
                            width: 70%;
                            padding-left: .5rem;
                        }
                    }
                }
        }
    }
