$value:10;

@while $value>0 {
  .mt__#{$value} {
    margin-top: $value + 0rem!important;
  }

  $value:$value - 1;
}
$value:10;

@while $value>0 {
  .mb__#{$value} {
    margin-bottom: $value + 0rem!important;
  }

  $value:$value - 1;
}
.mb__0 {
  margin-bottom: 0!important;
}
$value:10;

@while $value>0 {
  .ml__#{$value} {
    margin-left: $value + 0rem!important;
  }

  $value:$value - 1;
}
.ml__0 {
  margin-left: 0!important;
}

$value:10;

@while $value>0 {
  .mr__#{$value} {
    margin-right: $value + 0rem!important;
  }

  $value:$value - 1;
}


$value:10;

@while $value>0 {
  .pt__#{$value} {
    padding-top: $value + 0rem!important;
  }

  $value:$value - 1;
}
$value:10;

@while $value>0 {
  .pb__#{$value} {
    padding-bottom: $value + 0rem!important;
  }

  $value:$value - 1;
}
$value:10;

@while $value>0 {
  .pl__#{$value} {
    padding-left: $value + 0rem!important;
  }

  $value:$value - 1;
}
$value:10;

@while $value>0 {
  .pr__#{$value} {
    padding-right: $value + 0rem!important;
  }

  $value:$value - 1;
}
