.button {
    text-decoration: none;

    &.default {
        // background-color: $marine-blue-dark;
        background-color: $coral;
        border-radius: 5px;
        text-decoration: none;
        font-weight: bold;
        color: $dark-gray;
        @include shadow($prototype-box-shadow);

        &:hover,
        &:focus {
            background-color: lighten($color: $coral, $amount: 5);
            color: $black;
        }
    }

    &.round {
        border-radius: 5px;
    }
    &.bold {
        font-weight: bold;
    }
}